nav, #outputFilters
  display: none

p
  font-weight: 300 !important

#runInputs
  width: 100% !important
  max-width: 100% !important
  svg
    display: none
  .collapse
    display: block !important

